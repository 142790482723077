:root {
  --primary: #993bc5;
  --primary: #472278;
  --secondary: #57246e;
  --secondary: #1F0E32;
  --text: white;
  --text-secondary: blue;
  --sidebar-width: 280px;

  --primary: #0f1642;
  --secondary: #1f2b77;
  --primary-bxsh: rgba(15, 45, 66, 0.4);
  --highlight1: #9949fe;
  --highlight2: #1f0e33;
}

html {
  color: var(--text);
  background-color: #211225;
  background-color: #0e0e0e;
  background-color: #000000;
  font-family: 'Outfit', sans-serif;
}

button, input {
  font-family: 'Outfit', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root, .App, .Layout {
  height: 100%;
}

body {
  overflow-x: hidden;
}

h1 {
  margin-bottom: 1em;
}

.Background {
  position: absolute;
  top: 0;
  width: 100%;

  z-index: -999;
  pointer-events: none;

  background-size: cover;
  opacity: .4;
}


@media screen and (min-width: 960px) {
  .Layout {
    display: grid;
    grid-template-columns: var(--sidebar-width) 1fr;
  }

  .Content {

    display: flex;
    flex-direction: column;
    //justify-content: center;

    padding: 0 2em;
    flex:1;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
}

.button {
  padding: 10px 21px;
  color: white;
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: transparent;
  outline: transparent;

  text-align: left;
  border-radius: 4px;
  cursor: pointer;

  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;

  transition: all 0.17s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.16);
  }

  &.active {
    color: black;
    background: white;
    box-shadow:
            0px 0 15px 3px rgba(153, 59, 197, 0.44),
            1px 1px 1px 0px rgba(255, 56, 56, 0.52),
            -1px -1px 1px 0px rgba(0, 217, 255, 0.59);
  }
}

.IconButton {
  display: grid;
  grid-template-columns: 38px 1fr;
  align-items: center;

  img {
    width: 24px;
  }

  &.active img {
    filter: invert(1);
  }

  &.Button__IconOnly {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
  }
}