input[type="email"],
input[type="password"]  {
  border: none;
  box-shadow: none;
  outline: none;
  color: white;

  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.27);

  background-color: rgba(255, 255, 255, 0.18);
  background-color: rgba(255, 255, 255, 0.09);
  //background-color: #393575;
  font-size: 1rem;

  padding: 10px;
  padding-top: 30px;
  margin-top: 2px;

  &:focus {
    border-bottom: 2px solid rgba(255, 255, 255, 0.98);
  }

  //margin-bottom:  15px;
}

label {
  //margin-top: 3px;
  //margin-bottom: 4px;
  text-indent: 7px;
  transform: translate(2px, 23px);
  opacity: 0.7;
  font-weight: 300;
  font-size: .8rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}