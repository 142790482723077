.Panels {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 65px;

  margin: 1em 0;
  padding: 1em;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Panel  {
  border: 12px;
  padding: 0;

  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.EmojiBoard {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  z-index: 399;

  .EmojiBoardEntry {
    z-index: 999;
    padding: 0 1px;
  }

  .EmojiBoardEntry button {

    z-index: 1;

    appearance: none;
    border: none;
    background-color: transparent;
    color: white;
    box-shadow: none;
    outline: none;

    width: 42px;
    height: 42px;

    font-size: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    user-select: none;

    opacity: 0.7;
    /*filter: brightness(0) invert(1);*/

    transition-property: filter, transform, opacity;
    transition-duration: 0.21s;
    transition-timing-function: cubic-bezier( 0.23, 1, 0.32, 1 );

    &:hover {
      filter: none;
      opacity: 1;
      transform: scale(1.3);
    }
  }
}

.EventDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4em;

  .EventDetails_Loader {
    max-width: 152px;
  }

  .ViewerCount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .3em;

    strong {
      font-size: 140%;
    }
  }
}

.Schedule {
  .ScheduleEntry {
    max-width: 509px;
    display: grid;
    grid-template-columns: 102px 32px 1fr;
    gap: 7px;

    p {
      display: none;
    }

    .ScheduleEntry_Time {
      margin-top: -4px;
    }

    .ScheduleEntry_TimelineVisual {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding-left: 8px;
      padding-right: 12px;

      .TimelineVisual_Dot {
        background-color: var(--highlight1);
        width: 15px;
        height: 15px;

        border-radius: 500px;
      }

      .TimelineVisual_Line {
        min-height: 63px;
        background-color: white;
        width: 1px;
      }
    }

    .ScheduleEntry_Details {
      margin-top: -4px;

      .Details_Title {
        font-weight: 600;
        text-transform: capitalize;
        color: var(--highlight1);
        margin-bottom: 2px;
      }

      .Details_Description {

      }
    }

    &:last-child {

      .TimelineVisual_Line {
        height: auto;
        min-height: unset;
        display: none;
      }
    }
  }

  .LiveNow {
    .ScheduleEntry_Time p {
      display: block;
      color: var(--highlight1);
    }
  }

  .PastEvent {
    filter: grayscale(1);
  }

  button {
    margin: 1em 0;
  }

  a {
    color: var(--highlight1) !important;
    text-decoration: none;
    cursor: pointer;
  }
}


@media screen and (max-width: 960px) {
  .Schedule {
    .ScheduleEntry {
      display: flex !important;
      flex-direction: column;
      margin-top: .8em;

      .ScheduleEntry_TimelineVisual {
        order: 6;
        //width: 12px;
        width: 24px;

        .TimelineVisual_Dot {
        }

        .TimelineVisual_Line {
          min-height: 23px;
        }
      }

      .ScheduleEntry_Details {
        order: 1;
      }

      .ScheduleEntry_Time {
        order: 2 !important;
      }

      &:last-child {
        .ScheduleEntry_TimelineVisual {
          display: none;
        }
      }
    }

    .LiveNow {
      .ScheduleEntry_Time p {
        display: inline;
        margin-left: 1em;
      }
    }
  }
}

.Schedule:not(.__ShowPastEvents) {
  .ScheduleEntry.PastEvent {
    display: none !important;
  }
}

//.Schedule:not(.__ShowUpcomingEvents) {
//  .ScheduleEntry.HiddenUpcomingEvent {
//    display: none !important;
//  }
//}

.Schedule:not(.__ShowMoreEvents) {
  .ScheduleEntry:not(.PastEvent) ~
  .ScheduleEntry:not(.PastEvent) ~
  .ScheduleEntry:not(.PastEvent) ~
  .ScheduleEntry:not(.PastEvent) ~
  .ScheduleEntry:not(.PastEvent)
  {
    display: none !important;
  }
}

.About {
  max-width: 600px !important;
  overflow: hidden;

  .AboutImgContainer {
    display: flex;
    flex-direction: column;
  }

  img {
    max-width: 600px;
    width: 100%;
    border-radius: 5px;
    height: auto;
  }
}

.Sponsors {
  max-width: 700px;
  width: 100%;

  h2 {
    text-transform: capitalize;
  }

  .SponsorImageWrapper {
    max-width: 152px;
    margin-top: 5px;
    margin-right: 10px;

    img {
      transition: all 0.12s ease;
    }
    &:hover img {
      transform: scale(1.07);
    }
  }

  .SponsorCategory_List {
    display: flex;
    flex-wrap: wrap;
  }

  img {
    border-radius: 15px;
    width: 100%;
  }
}

@media screen and (max-width: 660px) {
  .Sponsors {
    .SponsorCategory_List {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(127px, 1fr));
    }
  }
}

@media screen and (min-width: 960px) {
  .EmojiBoard {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0px;
  }
}


@media screen and (min-width: 560px) {
  .Panels {
    flex-direction: row;
  }
}


@media screen and (max-width: 660px) {
  .Panels {
    align-items: center;
    justify-content: center;

    h1 {
      text-align: center;
    }
  }
}

.Tabs {
  user-select: none;
}