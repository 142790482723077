.LoginPage {
  height: 100%;
  width: 80%;

  overflow: hidden;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  .LoginTitle {
    margin-bottom: .7em;
    margin-top: .5em;
  }

  .LoginBoxWrapper {
    display: flex;
    flex-direction: column;
    max-width: 360px;
  }

  .LoginBox {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.12);
    flex: 1;

    max-width: 360px;
    width: 100%;
    height: 100%;
    padding: 1em;

    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.03);
    border-top: none;

    > * {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: -7px;
      left: -1px;
      right: -1px;
      border-top: 8px solid #ffffff;
    }
  }

  .LoginBox_Fields {
    display: flex;
    flex-direction: column;

    margin-bottom: 1em;
  }

  .LoginBox_Controls {
    //width: 100%;
    //
    //display: flex;
    //
    //button {
    //  flex: 1;
    //}
  }

  .LoginPage_Background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2299;

    background-position: center;
    background-size: cover;
    z-index: -9;
  }

  .LoginErrorsWrapper {
    max-height: 0;
    transition: all 0.2s ease;
    overflow: hidden;
    margin-left: 1px;

    flex: 0;
    &.__visible {
      transition: all 0.7s ease;
      max-height: 90px;
      flex: 1;
    }
  }

  .LoginErrors {
    background: #7c1e1e;
    padding: 15px;
    padding-bottom: 13px;
  }

  header {
    margin-top: -8vw;
    max-height: 36vw;
    background-size: cover;
    background-position: center;
    z-index: -4;

    pointer-events: none;
    user-select: none;


    > div {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -3;

        background: linear-gradient(0deg, #000000, rgba(14, 14, 14, 0.48), rgba(14, 14, 14, 0), rgba(14, 14, 14, 0));
      }
    }

    img {
      position: relative;
      z-index: -16;
      margin: 0 auto;
    }
  }

  header img {
    width: 100vw;
    object-fit: contain;
  }
}

.Welcome {
   .Welcome_Background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2299;

    background-position: center;
    background-size: cover;
    z-index: -9;
  }
}

@media screen and (max-width: 1280px) {
  .LoginPage {
    width: 100%;

    main {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: end;
      flex: 1;
    }


    .LoginPage_Background {
      filter: blur(14px);

      background-position: top right;
    }

    //.LoginBox {
    //  background-color: rgba(0, 0, 0, 0.93);
    //}
    //
    //
    //@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    //  .LoginBox {
    //    background-color: rgba(255, 255, 255, 0.12);
    //    //backdrop-filter: blur(25px) !important;
    //  }
    //}

    main {
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 360px) {
  .LoginPage {
    width: 100vw;
    align-items: unset;

    .LoginPage_Background {
      filter: blur(3px);

      background-position: top right;
    }

    .LoginBox {
      margin: 0 !important;
    }

    main {
      justify-content: center;
      align-items: center;
      margin: 0;
    }
  }
}


.EventOver {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1em;
  z-index: 9999;

  .EventOver_Background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2299;

    background-position: center;
    background-size: cover;
    z-index: -9;
  }

  .EventOver_Content {
    flex: 1;
    max-width: 900px;;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);

    border-radius: 3px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    overflow: hidden;
  }

  .EventOver_Info {
    padding: 1em;
    z-index: 99999999;
    backdrop-filter: blur(5px);

    h1 {
      margin-bottom: .6em;
    }
  }
}

@media screen and (max-width: 640px) {


  .EventOver {
    font-size: 12px !important;
  }
}
