.VideoPlayer {
  background-color: black;
  overflow: hidden;
  //z-index: 2;
  position: relative;
  padding-top: 56.25%;
}

.VideoPlayer_Player {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .VideoPlayer {
    //z-index: 1;
    //margin-top: 58px;
  }
}
