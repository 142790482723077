.ReelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ReelAlert  {
    width: 100%;
    position: relative;
    min-height: 100px;

    strong, p {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 15px;
    }

    p {
      top: 38px;
    }
  }
}


.Reel {
  --fruit-width: 73px;
  --fruit-height: 54px;

  display: grid;
  grid-template-columns: repeat(3, var(--fruit-width));
  gap: 3px;

  border: 4px solid var(--highlight1);
  border-radius: 4px;

  position: relative;
  margin-bottom: 1em;

  transition: opacity 2s ease;

  &.onCooldown {
    opacity: 0.4;
  }

  .Row {
    max-height: calc(var(--fruit-height) * 3);
    position: relative;
    top: 0;
    height: 1000px;
    overflow: hidden;

    background-color: rgb(0, 0, 0);
  }

  .Fruits {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
  }

  .Fruit {
    width: var(--fruit-width);
    height: var(--fruit-height);

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid black;
  }

  &.spinning {
    .Row .Fruits {
      transition: all 2s ease-in-out;
    }

    .Row:nth-child(1) .Fruits {
      top: -600%;
      transition-delay: 0s;
    }

    .Row:nth-child(2) .Fruits {
      top: -600%;
      transition-delay: 0.15s;
    }

    .Row:nth-child(3)  .Fruits {
      top: -600%;
      transition-delay: 0.3s;
    }
  }

  .ReelOverlays {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .ReelOverlay_Row {
      top: var(--fruit-height);
      bottom: var(--fruit-height);
      width: 100%;
      height: var(--fruit-height);
      background-color: rgba(255, 255, 255, 0.08);
    }
  }
}



.Reel {
  user-select: none;
}