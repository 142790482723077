.Game {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .Game_Border {
    min-width: 200px;
    border: 1px solid rgba(154, 154, 154, 0.28);
    padding: 1em;
    border-radius: 12px;
    background-color: rgba(153, 59, 197, 0.39);

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    p {
      text-align: center;
      margin: 7px;
    }
  }

  .Game_ButtonContainer {
    margin-top: 1em;
    width: 100%;

    button {
      width: 100%;
      padding: 12px;
    }
  }
}