:root {
  --header-z: 999999999;
}

.ResponsiveNavigation {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  background-color: var(--primary);

  img {
    max-width: 40vw;
  }

  z-index: var(--header-z);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  max-height: 58px;
  min-height: 58px;
}

.Navigation {

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* background: linear-gradient(-45deg, #1d2448, #3d246e, #993bc5); */
  background-size: 300% 200%;
  background-color: var(--primary);
  animation: gradient 15s ease infinite;
  overflow-x: hidden;

  box-shadow: 0px 0 15px 3px rgba(153, 59, 197, 0.24);
  box-shadow: 0px 0 15px 3px var(--primary-bxsh);
  z-index: var(--header-z);

  width: var(--sidebar-width);

  .NavTitle {
    display: flex;
    justify-content: center;

    .TitleDesktop {
      padding-top: 15px;
      max-width: 85%;
    }

    .TitleMobile {
      display: none;
    }

    .TitleMobile_MenuToggle {
      display: none;
    }
  }

  .NavList {
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 15px;
    padding-bottom: 3em;
  }

  .NavBottom {
    padding: 15px;
  }
}

.NavBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.68);
  opacity: 0;

  z-index: 999;

  pointer-events: none;
  backdrop-filter: blur(3px);

  transition-property: opacity, left;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier( 0.23, 1, 0.12, 1) ;
}


@media screen and (max-width: 959px) {
  .ResponsiveNavigation {
    display: flex;
  }

  .TitleDesktop {
    display: none !important;
  }



  .Navigation {
    position: fixed;
    top: 58px;
    left: calc((var(--sidebar-width) * -1 ) - 50px );
    bottom: 0;
    box-shadow: none;

    transition-property: opacity, left;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier( 0.23, 1, 0.12, 1) ;

    opacity: 0.9;
    //@for $i from 1 to 17 {
    //  *:nth-child(#{$i}) {
    //    transition-delay: #{$i * 0.02}s;
    //  }
    //}


    &.--open {
      left: 0;


    }

    .button {
      font-size: 0.9rem !important;
      font-size: 0.8rem !important;
    }


  }
  .NavBackdrop.--open {
    opacity: 1;
    pointer-events: all;
  }
}


@media screen and (min-width: 960px) {
  .Navigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--sidebar-width);
  }
}

.Tabs {
  /* background: linear-gradient(-45deg, #1d2448, #3d246e, #993bc5); */
  background-color: var(--primary);
  background-size: 300% 200%;
  animation: gradient 15s ease infinite;

  display: flex;
  justify-content: space-between;

  box-shadow: 0px 0 15px 3px var(--primary-bxsh);

  .TabsNav {
    display: flex;
    gap: 8px;
    padding: 15px;
  }
}

.TabsEmojiBoard {
  display: flex;
  align-items: center;
  padding-right: 1em;

  flex-direction: column;

  > p {
    margin-bottom: 1em;
  }
}

.TabsContent {
  position: relative;
  min-height: 400px;

  padding-top: 2em;
  padding: 2em 1em 0 1em;

  pointer-events: none;
  transition: height 0.2s ease;

  .Tab  {
    opacity: 0;
    pointer-events: none;
    //position: absolute;
    transform: translateX(-23px);
    transition: all 0.24s ease-in-out;
    //padding-bottom: 100px;

    &.active {
      opacity: 1;
      display: flex;
      transform: translateX(0px);
      pointer-events: all;
    }

    .menu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 2em;
      flex-wrap: wrap;
    }
  }
}

@media screen and (min-width: 960px) {
  .Tabs {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.TabsEmojiBoard {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 1em;
}

@media screen and (max-width: 960px) {
  .TabsNav {
    padding: 0px;

    button {
      flex: 1;
      text-align: center !important;
    }
  }

  .Tabs {
    flex-direction: column-reverse;
    padding: 0;
  }

  .TabsEmojiBoard {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .Tab  {
    .menu {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.Navigation, .NavBackdrop, .ResponsiveNavigation {
  user-select: none;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0);
  transition: all 0.2s ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 0.2s ease;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
